
import React, { Fragment } from 'react';
import { FieldArray, FormikProps, FieldArrayRenderProps } from 'formik'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { LeftAlignedBox, RightAlignedBox } from '../styledComponents/styledComponents'

interface MultipleDynamicFieldsProps<FormValues> {
  name: string
  label: string
  Formik: FormikProps<FormValues>
}

function MultipleDynamicFields<FormValues extends { heading: { heading: string, heading_code: string, subHeading: { subHeading: string, sub_heading_code: string }[] }[] }>(props: MultipleDynamicFieldsProps<FormValues>) {
  const { name, label, Formik } = props

  return (
    <Fragment>
      <Stack paddingLeft={2}>
        <FieldArray name={name}>
          {(fieldArrayProps: FieldArrayRenderProps) => {
            return (
              <Fragment>
                <LeftAlignedBox mt={3} mb={2} width="98%">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      fieldArrayProps.push({
                        heading: '',
                        heading_code: "",
                        subHeading: [{ subHeading: '', sub_heading_code: "" }],
                      });
                    }}
                  >
                    Add Heading +
                  </Button>
                </LeftAlignedBox>

                {/* Ensuring unique keys for mapped items */}
                {Formik.values.heading?.map((headingItem, headingIndex) => (
                  <Stack paddingLeft={2.5} key={`heading-${headingIndex}`}>
                    <Grid container spacing={2}
                      sx={{
                        border: "1px dashed",
                        borderRadius: "10px",
                        borderColor: (theme) => theme.palette.grey[500],
                        mb: 2, mt: 0.2, paddingRight: 2,
                        paddingBottom: "1rem",


                      }}
                    >
                      <RightAlignedBox mt={2} mb={1} width="100%">
                        <Button
                          variant="outlined"
                          color='error'
                          onClick={() => {
                            fieldArrayProps.remove(headingIndex);
                          }}
                        >
                          Remove Heading -
                        </Button>
                      </RightAlignedBox>

                      <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{ mb: 1 }}
                              label={`Heading ${headingIndex + 1}`}
                              name={`${name}[${headingIndex}].heading`}
                              placeholder={`Enter ${label} ${headingIndex + 1}`}
                              value={headingItem.heading}
                              onChange={(event) => {
                                Formik.setFieldValue(`${name}[${headingIndex}].heading`, event.target.value);
                              }}
                              error={Boolean(
                                Array.isArray(Formik.touched.heading) && // Ensure heading is an array
                                Formik.touched.heading[headingIndex] && // Ensure the index exists
                                Array.isArray(Formik.errors.heading) && // Ensure heading errors is an array
                                Formik.errors.heading[headingIndex] // Check if the specific index has an error
                              )}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                      {/* Sub-headers section */}
                      <FieldArray name={`${name}[${headingIndex}].subHeading`}>
                        {(subFieldArrayProps: FieldArrayRenderProps) => (
                          <Grid item xs={12} md={6}>
                            {headingItem.subHeading?.map((subHeadingItem, subHeadingIndex) => (
                              <Fragment key={`subheading-${headingIndex}-${subHeadingIndex}`}>
                                <FormControl fullWidth>
                                  <TextField
                                    sx={{ mb: 1 }}
                                    label={`Sub-Heading ${subHeadingIndex + 1}`}
                                    name={`${name}[${headingIndex}].subHeading[${subHeadingIndex}].subHeading`}
                                    placeholder={`Enter Sub Heading ${subHeadingIndex + 1}`}
                                    value={subHeadingItem.subHeading}
                                    onChange={(event) => {
                                      Formik.setFieldValue(
                                        `${name}[${headingIndex}].subHeading[${subHeadingIndex}].subHeading`,
                                        event.target.value
                                      );
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {/* Add button for sub-headings */}
                                          {subHeadingIndex === headingItem.subHeading.length - 1 && (
                                            <AddOutlinedIcon
                                              fontSize="small"
                                              onClick={() => {
                                                subFieldArrayProps.push({ subHeading: '' });
                                              }}
                                              sx={{ cursor: 'pointer', '&:hover': { color: (theme) => theme.palette.primary.main } }}
                                            />
                                          )}

                                          {/* Delete button for sub-headings */}
                                          {subHeadingIndex > 0 && (
                                            <DeleteOutlinedIcon
                                              fontSize="small"
                                              onClick={() => {
                                                subFieldArrayProps.remove(subHeadingIndex);
                                              }}
                                              sx={{ cursor: 'pointer', '&:hover': { color: (theme) => theme.palette.error.main } }}
                                            />
                                          )}
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={Boolean(
                                      Array.isArray(Formik.touched.heading) && // Ensure 'heading' is an array
                                      Array.isArray(Formik.errors.heading) && // Ensure 'heading' errors is an array
                                      typeof headingIndex === 'number' && // Ensure index is a number
                                      typeof subHeadingIndex === 'number' && // Ensure sub-heading index is a number
                                      (Formik.touched.heading[headingIndex] as any)?.subHeading?.[subHeadingIndex] && // Use type casting to access specific sub-heading
                                      (Formik.errors.heading[headingIndex] as any)?.subHeading?.[subHeadingIndex] // Ensure specific 'subHeading' error exists
                                    )}
                                  />
                                </FormControl>
                              </Fragment>
                            ))}
                          </Grid>
                        )}
                      </FieldArray>
                    </Grid>
                  </Stack>
                ))}
              </Fragment>
            );
          }}
        </FieldArray>
      </Stack>
    </Fragment>
  );
}

export default MultipleDynamicFields;

