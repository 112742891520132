import { Components } from '@mui/material/styles'

const MuiTextField: Components['MuiTextField'] = {
    styleOverrides: {},
    defaultProps: {
        size: "small",
        inputProps: {
            style: { fontFamily: 'Poppins' },

        },
        InputLabelProps: {
            style: { fontFamily: 'Poppins', },
            size: "small",
        },
        // placeholder:'Hello Ram'
    },
    // variants: [{props: { variant: 'outlined' },style: { fontFamily: 'Poppins' }}]
};

export default MuiTextField;
