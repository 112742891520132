import React, { Fragment, useState } from 'react'
import { FormikProps } from 'formik'

import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import { SvgIconProps } from '@mui/material/SvgIcon'
import InputAdornment from '@mui/material/InputAdornment'

import Loader from '../../components/Loader/Loader'

interface doubleButtonTextInputProps<initialState> {
    label: string
    loading: boolean
    disabled?: boolean
    placeholder?: string
    shouldShrink?: boolean
    isEditModeOn: boolean
    sx: React.CSSProperties
    name: keyof initialState
    executionFun1?: () => void
    executionFun2: () => void
    Formik: FormikProps<initialState>
    type?: React.HTMLInputTypeAttribute
    icon1: React.ElementType<SvgIconProps>
    icon2: React.ElementType<SvgIconProps>
    setEditModeOn: (status: boolean) => void
}

function DoubleButtonTextInput<initialState>(props: doubleButtonTextInputProps<initialState>) {
    const {
        name, label, shouldShrink, type, Formik, loading, disabled, isEditModeOn, setEditModeOn,
        placeholder, icon1: MUIIcon1, icon2: MUIIcon2, executionFun1, executionFun2, sx
    } = props

    const handleEditMode = () => {
        setEditModeOn(!isEditModeOn);
        executionFun1 && executionFun1()
    }

    return (
        <Fragment>
            <FormControl fullWidth>
                <TextField
                    fullWidth
                    sx={{ ...sx }}
                    type={type || 'text'}
                    name={name as string}
                    placeholder={placeholder}
                    value={Formik.values[name]}
                    onChange={Formik.handleChange}
                    disabled={disabled || !isEditModeOn}
                    InputLabelProps={{ shrink: shouldShrink }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {
                                    !isEditModeOn ? <MUIIcon1 color='primary' cursor='pointer' onClick={handleEditMode} />
                                        : <MUIIcon2 color='primary' cursor='pointer' onClick={() => executionFun2()} />
                                }
                            </InputAdornment>
                        )
                    }}
                    error={Boolean(Formik.touched[name] && Formik.errors[name])}
                    label={loading ? (<Loader padding="0 0" size={20} />) : label}
                />
            </FormControl>
        </Fragment>
    )
}

export default DoubleButtonTextInput;