import React, { Fragment } from 'react'
import classes from '../styles/credentials.module.css'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import siteLogo from '../../asset/logos/pmLogoLight.png'

interface formSalutationProps { text: string }

export default function FormSalutation({ text }: formSalutationProps) {

    return (
        <Fragment>
            <Stack className={classes.logoSection}>
                <img src={siteLogo} alt="logo" className={classes.siteLogo} />
                <Typography className={classes.siteName}>Pariksha Mantra</Typography>
                <Typography className={classes.welcomeText}>{text}</Typography>
            </Stack>
        </Fragment>
    )
};