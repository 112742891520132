import * as Yup from 'yup'
import dayjs, { Dayjs } from 'dayjs'


// <----- Form Props ----->
export type formProps = {
    setLoginForm: (status: boolean) => void
    setRegistrationForm: (status: boolean) => void
    setForgetPasswordForm: (status: boolean) => void
}

// <----- Form State ----->
export type loginState = {
    email: string
    accountType: string
    password: string | number
}

export const loginInitialValues: loginState = {
    accountType: '', email: '', password: ''
}

export type registrationState = {
    image: any, accountType: "institute" | "student",

    name: string, gender: 'male' | 'female'

    DOB: Dayjs | null, email: string, phone: string

    password: string | number, confirm_password: string | number

    agreeTC: boolean, status: "active" | "inactive"
}

export const registrationInitialValues: registrationState = {
    image: [], accountType: "student", name: '',

    gender: 'male', DOB: null, email: '', phone: '',

    password: '', confirm_password: '', agreeTC: false, status: 'active'
}

export type forgetPasswordState = {
    email: string
}

export const forgetPasswordInitialValues: forgetPasswordState = {
    email: ''
}

// <----- Form Validation ----->

export const loginVadidationSchema = Yup.object().shape({
    accountType: Yup.string().required('Account Type is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .min(6, 'Password must have at least 6 characters')
        .required('Password is required'),
})

export const registrationVadidationSchema = Yup.object().shape({
    name: Yup.string().required('User name is required'),
    gender: Yup.string().required('Gender is required'),
    phone: Yup.string()
        .matches(/^[6-9]\d{9}$/, 'Phone number must be 10 digits and cannot start with 0-5')
        .required('Phone number is required'),
    email: Yup.string().required('Email is required'),
    password: Yup.string()
        .min(6, 'Password must have at least 6 characters')
        .required('Password is required'),

    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Password must match')
        .required('Confirm password is required'),
    agreeTC: Yup.boolean().oneOf([true], 'Must agree to terms & conditions')
})

export const forgetPasswordValidationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
})

//<----- Greeting Notes ----->
export const loginGreetingNotes = {
    heading: "Login & Continue Your Success Journey!",
    subHeading: "Focus, Study, Succeed. Your Goals Are Closer Than You Think."
}

export const registrationGreetingNotes = {
    heading: "Welcome to your path of success!",
    subHeading: "Power Up Your Study Plan. Register & Dominate The Next Exam."
}
export const forgetPasswordGreetingNotes = {
    heading: "Lost Your Password ?",
    subHeading: "Recover Your Access, Reset Password & Continue Your Journey."
}