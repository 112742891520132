import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'

import SubMenu from './SubMenu'
import { mainMenuProps } from './interface'
import { MenuListItems, Modules } from '../styles/sideBarStyles'

export default function MainMenu(props: mainMenuProps) {
    const { id, name, path, icon: Icon, subMenu, isOpen, parent } = props

    const location = useLocation()
    const navigate = useNavigate()
    const { pathname } = location

    const [show, setShow] = useState<boolean>(false)
    const [isHovered, setIsHovered] = useState<boolean>(false)

    useEffect(() => {
        if (!isOpen) setShow(false)
    }, [isOpen])

    const handleHover = () => setIsHovered(true)
    const handleLeave = () => setIsHovered(false)

    const handleClick = () => {
        if (path !== "") navigate(path);
        if (subMenu.length) setShow(!show);
    }

    return (
        <Fragment>
            <MenuListItems key={id} onMouseEnter={handleHover} onMouseLeave={handleLeave}>
                <Icon
                    sx={{
                        marginLeft: '10px', cursor: 'pointer',
                        color: (isHovered || pathname?.includes(parent)) ? (theme) => theme.palette.primary.main : '',
                    }}
                />
                <Modules {...{ isOpen }} onClick={handleClick}>
                    <Typography
                        sx={{
                            width: '100%',
                            display: 'flex',
                            fontSize: '15px',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            '&:hover': { fontWeight: 500 },
                            fontWeight: pathname?.includes(parent) ? 500 : 400,
                            color: (isHovered || pathname?.includes(parent)) ? (theme) => theme.palette.primary.main : '',
                        }}
                    >
                        {name}
                        {subMenu.length ?
                            show ? <ExpandLessIcon fontSize='small' />
                                : <NavigateNextRoundedIcon fontSize='small' />
                            : null
                        }
                    </Typography>
                </Modules>
            </MenuListItems>

            <Collapse
                sx={{ width: "100%", height: "auto" }} in={show}
                timeout={{ appear: 500, enter: 500, exit: 500 }} unmountOnExit
            >
                <SubMenu subMenu={subMenu} />
            </Collapse>
        </Fragment>
    )
};