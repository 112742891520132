import React, { Fragment, useState, useRef } from 'react'
import classes from './styles/profilePicture.module.css'
import { FormikProps } from 'formik'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import AddAPhotoTwoToneIcon from '@mui/icons-material/AddAPhotoTwoTone'

import Modal from '../../../components/Dialog/Modal'
import profileIcon from '../../../asset/authentication/genderLessProfile.png'

import { base64ToBinary, ImageCropper } from '../index'

interface FileWithPreview extends File { preview: string }
interface profilePictureProps<initialState> {
    Formik: FormikProps<initialState>
    name: keyof initialState
    error: boolean
}

function ProfilePicture<initialState>(props: profilePictureProps<initialState>) {
    const { name, Formik, error } = props
    const imageFile = useRef(profileIcon)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    // Fetch The Formik Field Value For The Image-Field (Specially Used For Get By ID Time Value Setting).
    const fieldValue = Formik.values[name] as FileWithPreview[];
    const imagePreview = fieldValue?.[0]?.preview || imageFile.current;

    return (
        <Fragment>
            <Stack className={classes.imageContainer}>
                <Box className={classes.imageSection}>
                    <img src={imagePreview} alt="Image"
                        className={`${classes.imagePreview} ${error ? classes.imageError : ''}`}
                        onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            target.src = profileIcon
                        }}
                    />
                    <IconButton
                        sx={{
                            backgroundColor: (theme) => theme.palette.grey[300],
                            ":hover": {
                                backgroundColor: (theme) => theme.palette.grey[400],
                                mb: 0.2, color: (theme) => theme.palette.primary.main,
                            }
                        }}
                        className={classes.imageUploadButton}
                        onClick={() => setIsOpen(true)}>
                        <AddAPhotoTwoToneIcon />
                    </IconButton>
                </Box>
            </Stack>
            <Modal {...{ isOpen, setIsOpen }}>
                <ImageCropper {...{
                    setIsOpen,
                    buttonText: "Choose Profile Picture", isCircular: true,
                    croppedImage: (base64String: string) => base64ToBinary("profilePic", base64String, name, Formik, imageFile),
                }} />
            </Modal>
        </Fragment>
    )
}

export default ProfilePicture;