import React, { Fragment } from 'react'

import { Box } from '@mui/system'
import CircularProgress from '@mui/material/CircularProgress'

interface loaderProps {
    padding: string
    size: number
}
function Loader(props: loaderProps) {
    const { padding, size } = props
    return (
        <Fragment>
            <Box sx={{ padding: padding }}>
                <CircularProgress color='inherit' size={size} />
            </Box>
        </Fragment>
    )
}

export default Loader;