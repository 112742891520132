import React, { Fragment } from 'react'
import { useDropzone } from 'react-dropzone'
import classes from './styles/uploadBox.module.css'

import { styled, alpha } from '@mui/material/styles'

import Typography from '@mui/material/Typography'
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'

import iconFile from '../../../asset/uploadBox/iconFile.png'

const StyledDropZone = styled("div")(({ theme }) => ({
    width: 64,
    fontSize: 24,
    flexShrink: 0,
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.disabled,
    backgroundColor: alpha(theme.palette.grey[500], 0.08),
    "&:hover": {
        opacity: 0.72,
    },
}));

const StyledComponent = styled("div")(({ theme }) => ({
    fontSize: 24,
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    borderRadius: "10px",
    flexDirection: "column",
    justifyContent: "flex-end",
    color: theme.palette.text.secondary,
    border: `solid 2px ${theme.palette.divider}`,
    "&:hover": {
        opacity: 0.72,
    },
}));

interface imageUploadProps {
    file: any
    accept: any
    name: string
    label: string
    error: boolean
    disabled?: boolean
    otherFile?: boolean
    onDrop: (acceptedFiles: File[]) => void
}

export default function ImageUpload(props: imageUploadProps) {
    const { disabled, error, file, label, otherFile, accept, name, ...others } = props


    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        accept: accept,
        disabled, ...others
    })

    const isError = isDragReject || !!error
    const imageURL = typeof file === "string" ? file : file?.preview
    return (
        <Fragment>
            <StyledComponent
                className={classes.uploadBoxContainer}
                {...getRootProps()}
                sx={{
                    height: 150,
                    width: "100%",
                    // maxWidth: "500px",
                    ...(isError && { color: "error.main", borderColor: "error.light" }),
                    ...(imageURL && { alignItems: { xs: "center", sm: "end" }, justifyContent: { xs: "flex-end", sm: "flex-start" }, padding: "5px" })
                }}
            >

                <StyledDropZone
                    sx={{
                        height: "70px",
                        width: "auto",
                        ...(isDragActive && { opacity: 0.72, }),
                        ...(isError && { color: "error.main", bgcolor: "error.lighter", borderColor: "error.light" }),
                        ...(disabled && { opacity: 0.48, pointerEvents: "none" }),
                        ...(imageURL && { borderRadius: "5px", border: (theme) => `solid 1px ${theme.palette.divider}` }),
                        ...(isError && { backgroundColor: 'transparent' }),

                    }}
                >
                    <input {...getInputProps()} />
                    {
                        Boolean(file?.preview) ?
                            (
                                <img
                                    src={otherFile ? iconFile : imageURL}
                                    height={otherFile ? "50%" : "100%"}
                                    width={otherFile ? "50%" : "100%"}
                                    style={{ objectFit: "cover", borderRadius: 8 }}
                                    alt=""
                                />
                            ) : typeof file === "string" ? (
                                <img
                                    src={otherFile ? iconFile : imageURL}
                                    height={otherFile ? "50%" : "100%"}
                                    width={otherFile ? "50%" : "100%"}
                                    style={{ objectFit: "cover", borderRadius: 8 }}
                                    alt=""
                                />
                            )
                                : <CloudUploadTwoToneIcon fontSize='large' sx={{ mb: -3 }} />
                    }
                </StyledDropZone>

                <Typography sx={{ mr: { xs: 0, sm: 1 } }}>
                    {
                        Boolean(file?.preview) ? file.name.slice(0, 20)
                            : typeof file === "string" ? file.slice(0, 44)
                                : `Upload ${label?.slice(0, 10)}` || "Upload Files"
                    }
                </Typography>
            </StyledComponent>
        </Fragment >
    )
}