import React, { Fragment } from 'react'
import { FormikProps } from 'formik'

import Paper from '@mui/material/Paper'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'


interface imageViewerProps<initialState> {
    name: keyof initialState
    Formik: FormikProps<initialState>
}

export default function ImageViewerSmall<initialState>(props: imageViewerProps<initialState>) {
    const { name, Formik } = props

    const image = Formik.values[name] as unknown as { preview: string, [key: string]: any }[];
    return (
        <Fragment>
            {
                image[0] ?
                    (
                        <Fragment>
                            <Paper elevation={2} sx={{
                                mt: 0.5, p: 0.5, maxWidth: "100px", height: "auto",
                                display: "flex", alignItems: "center", justifyContent: "center",
                                backgroundImage: `url(${image[0].preview})`,
                                backgroundSize: 'cover', backgroundPosition: 'center',
                                transition: 'background-image 0.5s ease-in-out',
                            }}>

                                <HighlightOffIcon fontSize='small'
                                    onClick={() => Formik.setFieldValue(name as string, [])}
                                    sx={{
                                        zIndex: 100, position: "relative", top: -6, left: 50,
                                        color: "gray", ":hover": { cursor: "pointer", color: (theme) => theme.palette.error.main }
                                    }} />
                            </Paper>
                        </Fragment>
                    ) : null
            }
        </Fragment>
    )
}