import React, { Fragment, useEffect } from 'react'

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'

import { sideNavBarProps } from './components/interface'
import {
    SideBar, SiteLogoName, SiteName, BurgerContainer,
    burgerIconStyles, MenuContainer, MenuList
} from './styles/sideBarStyles'

import MainMenu from './components/MainMenu'
import ActionMenu from './components/ActionMenu'
import sideBarLogo from '../../../asset/logos/sideBarLogo.svg'

// API Section :::
import { useAppDispatch } from '../../../redux/store/hooks'
import { getCurrentUserDetailsAsync } from '../../../redux/slice/auth/authAsync'


function SideNavBar(props: sideNavBarProps) {
    const { isOpen, setIsOpen, menuItems } = props
    const dispatch = useAppDispatch()

    const handleMenues = () => { setIsOpen(!isOpen) }

    useEffect(() => {
        dispatch(getCurrentUserDetailsAsync())
    }, [])

    return (
        <Fragment>
            <SideBar elevation={5}  {...{ isOpen }}>

                <SiteLogoName>
                    <img src={sideBarLogo} alt="Site Logo"
                        style={{ width: '30px', height: 'auto', borderRadius: '10px', marginLeft: '10px' }}
                    />
                    {/* <SiteLogo /> */}
                    <SiteName  {...{ isOpen }}>Pariksha Mantra</SiteName>
                </SiteLogoName>

                <BurgerContainer  {...{ isOpen }} onClick={() => handleMenues()}>
                    {
                        isOpen ? <ChevronLeftRoundedIcon sx={burgerIconStyles} />
                            : <ChevronRightRoundedIcon sx={burgerIconStyles} />
                    }
                </BurgerContainer>

                <MenuContainer>
                    <MenuList>
                        {
                            menuItems?.length ? menuItems?.map((items) => {
                                const { id, name, icon, parent, path, subMenu } = items
                                return (
                                    <Fragment key={id}>
                                        <MainMenu {...{ id, name, icon, parent, path, subMenu, isOpen }} />
                                    </Fragment>
                                )
                            }) : null
                        }
                    </MenuList>
                </MenuContainer>

                <ActionMenu {...{ isOpen }} />

            </SideBar>
        </Fragment>
    )
}

export default SideNavBar;