import { createBrowserRouter } from 'react-router-dom'

import WebLayout from '../../layout/root/WebLayout'

import Suspense from '../zSuspense/Suspense'

import {
    LandingPage, Vacancies, CurrentAffairs,
    TopicWiseCurrentAffair, Exams,
    Authentication, TermsAndConditions, PageNotFound,
} from './components/routeLazyLoading'


const WebRoutes = createBrowserRouter([
    {
        path: '/',
        element: <WebLayout />,
        errorElement: <Suspense><PageNotFound /></Suspense>,
        children: [
            { path: '/', element: <Suspense><LandingPage /></Suspense> },
            { path: '/exams', element: <Suspense><Exams /></Suspense> },
            { path: '/vacancies', element: <Suspense><Vacancies /></Suspense> },
            { path: '/currentAffairs', element: <Suspense><CurrentAffairs /></Suspense> },
            { path: '/currentAffairs/:geographicalFocus/:category/:id', element: <Suspense><TopicWiseCurrentAffair /></Suspense> },
            { path: '/auth', element: <Suspense><Authentication /></Suspense> },
            { path: '/terms-conditions', element: <Suspense><TermsAndConditions /></Suspense> },
        ]
    }
])


export default WebRoutes;