import React, { Fragment, useState, useEffect, useMemo } from "react"
import { bottomNavigationDrawerProps } from "./components/interface"
import { useNavigate, useLocation } from 'react-router-dom'
import "./components/bottomDrawer.css"

import Chip from "@mui/material/Chip"
import Paper from "@mui/material/Paper"

function BottomNavigationDrawer(props: bottomNavigationDrawerProps) {
    const { name, subMenu, icon: Icon, currentMenu } = props
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location

    const [isOpen, setIsOpen] = useState<boolean>(true)
    const [showSubMenu, setShowSubMenu] = useState(false)
    const [currentPageName, setCurrentPageName] = useState<string>('')


    // To Set Sub-Menu
    useEffect(() => {
        if (subMenu?.length > 0) {
            setShowSubMenu(true)
            // When Page Reloaded & Sub-Menu Was Selected Before Reload
            const currentSubMenu = subMenu.find((ele) => ele.path === pathname)
            setCurrentPageName(currentSubMenu?.name ? currentSubMenu?.name : "")
        } else {
            setShowSubMenu(false)
        }
    }, [subMenu])

    // To Set Sub-Menu With Main-menu 
    const handleRoute = (name: string) => {
        setCurrentPageName(name)
        setTimeout(() => {
            setIsOpen(!isOpen)
        }, 1000)
    }

    // To Clear Sub-Menu When Main-menu Changes And
    useMemo(() => {
        setCurrentPageName('')
        setIsOpen(true)
    }, [currentMenu])


    return (
        <Fragment>
            <div className={showSubMenu ? 'subMenu active' : 'subMenu'}>
                <div className="menuContainer">
                    <Paper elevation={10} sx={{ borderRadius: '30px' }}>
                        {
                            isOpen ? (
                                <Chip   // <----- Menu Sub-Menu & Icon ----->
                                    label={currentPageName !== '' ? `${name} - ${currentPageName}` : name}
                                    avatar={<Icon fontSize="small" sx={{ cursor: 'pointer' }} />}
                                    onClick={() => setIsOpen(!isOpen)}
                                />
                            ) : (
                                <Chip  // <----- Just Menu Icon ----->
                                    avatar={<Icon fontSize="small"
                                        sx={{
                                            cursor: 'pointer', position: 'relative', left: '5px',
                                            transition: '5s', opacity: "0.5"
                                        }}
                                    />}
                                    onClick={() => setIsOpen(!isOpen)}
                                />
                            )
                        }

                    </Paper>
                    {   // <----- Sub-Menus ----->
                        subMenu?.length ? subMenu.map((item) => {
                            return (
                                <Paper elevation={10} key={item.id}
                                    sx={{ borderRadius: '30px', opacity: isOpen ? 1 : 0, transition: '0.5s' }}
                                >
                                    <Chip
                                        label={item.name}
                                        onClick={() => {
                                            navigate(item.path)
                                            handleRoute(item.name)
                                        }}
                                    />
                                </Paper>
                            )
                        }) : null
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default BottomNavigationDrawer;
