import React, { Fragment } from 'react'

import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

interface modalProps {
    isOpen: boolean
    children: React.ReactNode
    clearDependencies?: () => void
    setIsOpen: (status: boolean) => void
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl"
}

function Modal(props: modalProps) {
    const { isOpen, setIsOpen, clearDependencies, children, maxWidth } = props

    return (
        <Fragment>
            <Dialog
                onClose={() => setIsOpen(true)}
                open={isOpen}
                maxWidth={maxWidth || "lg"}
            >
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        setIsOpen(false)
                        if (clearDependencies) clearDependencies()
                    }}
                    sx={{
                        position: 'absolute',
                        left: "93%",
                        top: 5,
                        color: (theme) =>
                            theme.palette.mode === 'dark' ?
                                theme.palette.grey[100]
                                : theme.palette.grey[900],
                        ":hover": { color: (theme) => theme.palette.error.main }

                    }}
                >
                    <CloseIcon />
                </IconButton>
                {children}
            </Dialog>
        </Fragment>
    )
}

export default Modal;