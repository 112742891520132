import { createSlice } from "@reduxjs/toolkit";
import { authLogin } from './authAsync';

interface authLoginState {
    loading: boolean
    responseData: { code: number | null, message: string, token: string }
}

const initialState: authLoginState = {
    loading: false,
    responseData: { code: null, message: "", token: "" }
}

const authLoginSlice = createSlice({
    name: "authLoginSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addCase((authLogin.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((authLogin.fulfilled), (state, action) => {
            state.loading = false
            state.responseData = action.payload
            const authToken = { token: action.payload?.token }
            localStorage.setItem("auth", JSON.stringify(authToken))
        })
        builder.addCase((authLogin.rejected), (state, action) => {
            state.loading = false
        })
    },
    reducers: { emptyAuthLoginState: () => initialState },
})

export const { emptyAuthLoginState } = authLoginSlice.actions;
export default authLoginSlice.reducer;