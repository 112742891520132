import React, { useMemo } from 'react'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { useThemeContext } from './ThemeContextProvider'
import { CssBaseline, createTheme } from '@mui/material'
import { StyledEngineProvider, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import {
    palette, breakpoints, MuiButton, MuiTypography, MuiAutocomplete,
    getMuiAppBar, getMuiBottomNavigation, MuiTextField, MuiSelect,
    MuiLink, MuiMenuItem, MuiFormLabel, MuiInputLabel, getMuiDialog,
    getMuiDialogTitle, getMuiDialogContent, getMuiDialogContentText,
    MuiStep, MuiStepLabel,
} from './components'


interface themeProviderProps { children: React.ReactNode }

function ThemeProvider({ children }: themeProviderProps) {
    const { mode } = useThemeContext()

    const appliedTheme = useMemo(() =>
        createTheme({
            palette: palette(mode),
            breakpoints,
            components: {
                MuiStep,
                MuiLink,
                MuiButton,
                MuiSelect,
                MuiMenuItem,
                MuiFormLabel,
                MuiStepLabel,
                MuiTextField,
                MuiInputLabel,
                MuiTypography,
                MuiAutocomplete,
                MuiFormControl: {},
                MuiAppBar: getMuiAppBar(mode),
                MuiDialog: getMuiDialog(breakpoints),
                MuiDialogTitle: getMuiDialogTitle(mode),
                MuiDialogContent: getMuiDialogContent(mode),
                MuiBottomNavigation: getMuiBottomNavigation(mode),
                MuiDialogContentText: getMuiDialogContentText(mode),
            },
        }),
        [mode])

    return (
        <StyledEngineProvider injectFirst>
            <MUIThemeProvider theme={appliedTheme}>
                <CssBaseline />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {children}
                </LocalizationProvider>
            </MUIThemeProvider>
        </StyledEngineProvider>
    );
}

export default ThemeProvider;
