import { Components } from '@mui/material/styles'

const MuiTypography: Components['MuiTypography'] = {
    styleOverrides: {
        root: {
            fontFamily: "Poppins",
        },
    },
    defaultProps: {
        variant: "subtitle2"
    }
};

export default MuiTypography;