import { AxiosClient } from "../../axiosClient/AxiosClient";
import { createAsyncThunk } from "@reduxjs/toolkit";


// User Registration / Creation :::
export const authRegistration = createAsyncThunk(
    "registration/authRegistration",
    async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/users", payload, toolkit)
    }
);

// User Login :::
export const authLogin = createAsyncThunk(
    "login/authLogin",
    async (payload: { username: string, password: string | number }, toolkit) => {
        return await AxiosClient("POST", "/login", payload, toolkit)
    }
);

// Login With Google :::
export const authLoginWithGoogle = createAsyncThunk(
    "googleAuthLogin/authLoginWithGoogle",
    async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/google/login", payload, toolkit)
    }
);

// Save Current User Details :::
export const saveCurrentUserDetails = createAsyncThunk(
    "login/saveCurrentUserDetails",
    async (payload, toolkit) => {
        return await AxiosClient("GET", "/login", [], toolkit)
    }
)

// Get Current User Details :::
export const getCurrentUserDetailsAsync = createAsyncThunk(
    "login/getCurrentUserDetails",
    async (payload, toolkit) => {
        return await AxiosClient("GET", "/login", [], toolkit)
    }
)

// All Existing Users List :::
interface usersParams { search?: string | null, accountType?: string | null, userType?: string | null, status?: "active" | "inactive", page?: number, limit?: number }
export const getAllusersAsync = createAsyncThunk(
    "users/getAllusers", async (params: usersParams, toolkit) => {
        return await AxiosClient("GET", "/users", [], toolkit, params)
    }
);

// User Details By ID :::
export const getUserDetailsByIDAsync = createAsyncThunk(
    "users/getUserDetailsByID", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("GET", `/users/${payload.id}`, [], toolkit)
    }
);

// Update User Details By ID  :::
export const updateUserDetailsByIDAsync = createAsyncThunk(
    "users/updateUserDetailsByID", async (payload: { id: number, formData: FormData }, toolkit) => {
        return await AxiosClient("POST", `/users/${payload.id}`, payload.formData, toolkit)
    }
)

// Active/Inactive Status:::
export const userCurrentStatusUpdateAsync = createAsyncThunk(
    "/users/update/status/userCurrentStatusUpdate", async (payload: { id: number, status: "active" | "inactive" }, toolkit) => {
        return await AxiosClient("POST", "/users/update/status", payload, toolkit)
    }
)

// User Password Change:::
export const userPasswordChangeAsync = createAsyncThunk(
    "users/users/update/password", async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/users/update/password", payload, toolkit)
    }
)

// Delete User :::
export const deleteUserAsync = createAsyncThunk(
    "users/deleteUser", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("DELETE", `/users/${payload.id}`, [], toolkit)
    }
)

// Guest User Token Generation To Access Web Panel :::
export const authGuestUserTokenGeneration = createAsyncThunk(
    "guestUser/authGuestUser",
    async (payload, toolkit) => {
        return await AxiosClient("GET", "/generate-guest-token", [], toolkit)
    }
)

