import { FormikProps } from 'formik'

export default function base64ToBinary<initialState>(
    title: string,
    base64String: string,
    name: keyof initialState,
    Formik: FormikProps<initialState>,
    imageFile: React.MutableRefObject<string>
) {
    imageFile.current = base64String;
    try {
        // Converting To File System
        // Step 01) Decoding base64 to a "Binary String"
        const byteString = atob(base64String.split(",")[1])

        // Step 02) Getting "Mime Type" from the base64 string (Like : image/png)
        const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0]
        const mimeExtensionPart = mimeString.split("/")[1]

        // Step 03) Converting the "Binary String" to an array of bytes and populating Uint8Array
        const arrayBuffer = new ArrayBuffer(byteString.length)
        const uint8Array = new Uint8Array(arrayBuffer)
        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }
        // Step 04) Creating Blob
        const blob = new Blob([uint8Array], { type: mimeString })
        // Step 05) Creating File
        const file = new File([blob], `${title}.${mimeExtensionPart}`, { type: mimeString })

        const newFile = Object.assign(file, {
            preview: URL.createObjectURL(file),
            path: `/${title}.${mimeExtensionPart}`,
        });

        if (file) { Formik.setFieldValue(name as string, [newFile]) }
        else { return [] }
    } catch (error) {
        console.log("Something went wrong while storing image:", error)
    }
};
