import { createSlice } from "@reduxjs/toolkit";
import { authGuestUserTokenGeneration } from './authAsync';

interface authGuestUserState {
    loading: boolean
    responseData: { code: number, message: string, guest_token: string } | null
}

const initialState: authGuestUserState = {
    loading: false,
    responseData: null
}

const authGuestUserSlice = createSlice({
    name: "authGuestUserSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addCase((authGuestUserTokenGeneration.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((authGuestUserTokenGeneration.fulfilled), (state, action) => {
            state.loading = false
            state.responseData = action.payload
            const authToken = { token: action.payload?.guest_token }
            localStorage.setItem("auth", JSON.stringify(authToken))
        })
        builder.addCase((authGuestUserTokenGeneration.rejected), (state, action) => {
            state.loading = false
        })
    },
    reducers: { emptyGuestUserState: () => initialState },
})

export const { emptyGuestUserState } = authGuestUserSlice.actions;
export default authGuestUserSlice.reducer;
