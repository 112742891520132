import { alpha } from '@mui/material/styles'
import { PaletteMode, PaletteOptions } from '@mui/material'

export const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
};

// <----- Color Intensity ::: lighter:200 | light:300 | main:500 | dark:700 | darker:800 ----->

export const PRIMARY = {
    lighter: '#80cbc4',
    light: '#4db6ac',
    main: '#009688',
    // main: '#FBB885',
    dark: '#00796b',
    darker: '#00695c',
    contrastText: '#fff',
};

export const SECONDARY = {
    lighter: '#f48fb1',
    light: '#f06292',
    main: '#e91e63',
    dark: '#c2185b',
    darker: '#ad1457',
    contrastText: '#fff',
};

export const INFO = {
    lighter: '#CAFDF5',
    light: '#61F3F3',
    main: '#00B8D9',
    dark: '#006C9C',
    darker: '#003768',
    contrastText: '#fff',
};

export const SUCCESS = {
    lighter: '#a5d6a7',
    light: '#81c784',
    main: '#4caf50',
    dark: '#388e3c',
    darker: '#2e7d32',
    contrastText: '#fff',
};

export const WARNING = {
    lighter: '#FFF5CC',
    light: '#FFD666',
    main: '#FFAB00',
    dark: '#B76E00',
    darker: '#7A4100',
    contrastText: GREY[800],
};

export const ERROR = {
    lighter: '#FFE9D5',
    light: '#FFAC82',
    main: '#FF5630',
    dark: '#B71D18',
    darker: '#7A0916',
    contrastText: '#fff',
};

// <----- Gradient Colors ----->

function createGradient(color1: string, color2: string) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

const GRADIENTS = {
    primary: createGradient(PRIMARY.light, PRIMARY.main),
    secondary: createGradient(SECONDARY.light, SECONDARY.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main),
};

export const colorPalette = {
    primary: PRIMARY,
    secondary: SECONDARY,
    success: SUCCESS,
    info: INFO,
    warning: WARNING,
    error: ERROR,
    grey: GREY,
    gradients: GRADIENTS,
    divider: alpha(GREY[500], 0.24),
    action: {
        hover: alpha(GREY[500], 0.08),
        selected: alpha(GREY[500], 0.16),
        disabled: alpha(GREY[500], 0.8),
        disabledBackground: alpha(GREY[500], 0.24),
        focus: alpha(GREY[500], 0.24),
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    },
}

export default function palette(mode: PaletteMode): PaletteOptions {
    const light: PaletteOptions = {
        ...colorPalette,
        mode: 'light',
        text: {
            primary: GREY[800],
            secondary: GREY[600],
            disabled: GREY[500],
        },
        background: { paper: '#fff', default: '#fff' },
        action: {
            ...colorPalette.action,
            active: GREY[600],
        },
        gradients: GRADIENTS,
    };

    const dark: PaletteOptions = {
        ...colorPalette,
        mode: 'dark',
        text: {
            primary: '#fff',
            secondary: GREY[500],
            disabled: GREY[600],
        },
        background: {
            paper: GREY[800],
            default: GREY[900],
        },
        action: {
            ...colorPalette.action,
            active: GREY[500],
        },
        gradients: GRADIENTS,
    };

    return mode === 'light' ? light : dark;
}




