import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import { styled, alpha, Theme } from "@mui/material/styles"

export const primaryColor = { color: "primary.main" }
export const hoverErrorColor = { ":hover": { color: "error.main" } }
export const hoverPrimaryColor = { ":hover": { color: "primary.main" } }
export const hoverBoxShadow = { boxShadow: "5px 5px 5px rgb(0, 0, 0, 0.5)" }

export const LeftAlignedBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
}))

export const RightAlignedBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
}))

export const CenterAlignedBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

export const ButtonBoxRightAligned = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
}))

export const MountedBox = styled("div")(({ theme }) => ({
    right: 0,
    bottom: 0,
    zIndex: 1100,
    // zIndex: 9998,
    width: "100%",
    height: "100%",
    position: "fixed",
    overflow: "auto",
    backgroundColor: theme.palette.background.default,
    "&::-webkit-scrollbar": {
        width: "3px !important",
        height: "5px !important"
    },
    "&::-webkit-scrollbar-track": {
        background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "linear-gradient(#22a8ae, #88aa85) !important",
        borderRadius: "20px",
    }
}))

export const StyledPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: "10px",
    ":hover": {
        ...hoverBoxShadow,
        cursor: "pointer",
    }
}))

export const statusPaperStyles = (status: string) => (theme: Theme) => ({
    width: 80, height: 30, borderRadius: 20,
    display: "flex", alignItems: "center", justifyContent: "center",
    boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.1)`,
    color: status === 'active' ? (theme.palette.mode === "light"
        ? theme.palette.primary.dark : theme.palette.primary.light)
        : theme.palette.error.main,
})

