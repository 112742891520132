import React, { Suspense, ReactNode } from 'react';
import LoadingScreen from '../../components/loadingScreen/LoadingScreen'

interface SuspenseFallbackProps {
    children: ReactNode;
}

function SuspenseFallback(props: SuspenseFallbackProps) {
    const { children } = props

    return (
        <Suspense fallback={<LoadingScreen />}>
            {children}
        </Suspense>
    );
}

export default SuspenseFallback;

