import { createSlice } from '@reduxjs/toolkit';
import { AxiosClient } from "../../../axiosClient/AxiosClient";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { APIStatus, paginationData } from '../common';

// Category Scope :::
export const categoryScope = [
    { id: 1, label: "Culture & Language", value: "language&Culture" },
    { id: 2, label: "Defence", value: "defence" },
    { id: 3, label: "Economics & Finance", value: "economics&Finance" },
    { id: 4, label: "Entertainment", value: "entertainment" },
    { id: 5, label: "Governance & Law", value: "governance&Law" },
    { id: 6, label: "Geography", value: "geography" },
    { id: 7, label: "History", value: "history" },
    { id: 8, label: "Literature & Arts", value: "literature&Arts" },
    { id: 9, label: "Miscellaneous", value: "miscellaneous" },
    { id: 10, label: "Polity", value: "polity" },
    { id: 11, label: "Philosophy & Psychology", value: "philosophy&Psychology" },
    { id: 12, label: "Science & Nature", value: "science&Nature" },
    { id: 13, label: "Sports", value: "sports" },
    { id: 14, label: "Technology", value: "technology" }
];


// Get All Current Affairs Category :::
interface categoryParams { search?: string | null, page?: number, limit?: number, status?: "active" | "inactive" }
export const getAllCurrentAffairCategoriesAsync = createAsyncThunk(
    "getAllCurrentAffairCategories/category", async (params: categoryParams, toolkit) => {
        return await AxiosClient("GET", "/category", [], toolkit, params)
    }
);

export type categoriesOptions = "defence" | "economics&Finance" | "entertainment" | "geography" |
    "governance&Law" | "history" | "language&Culture" | "literature&Arts" | "miscellaneous" |
    "philosophy&Psychology" | "polity" | "science&Nature" | "sports" | "technology"

export interface categoriesStructure {
    id: number, label: string, value: categoriesOptions
    status: string, created_at: string, updated_at: string
}

interface allCurrentAffairCategories extends APIStatus {
    data: categoriesStructure[]
    pagination: paginationData
}

interface currentAffairsCategoriesStates {
    loading: boolean
    getAllCurrentAffairCategories: allCurrentAffairCategories | null
}

const initialState: currentAffairsCategoriesStates = {
    loading: false,
    getAllCurrentAffairCategories: null
}

const currentAffairCategoriesSlice = createSlice({
    name: "currentAffairCategoriesSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addCase((getAllCurrentAffairCategoriesAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((getAllCurrentAffairCategoriesAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getAllCurrentAffairCategories = action.payload
        })
        builder.addCase((getAllCurrentAffairCategoriesAsync.rejected), (state, action) => {
            state.loading = false
        })
    },
    reducers: {
        emptyCurrentAffairCategories: () => initialState,
    }
})

export const { emptyCurrentAffairCategories } = currentAffairCategoriesSlice.actions;
export default currentAffairCategoriesSlice.reducer;