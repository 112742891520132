import React, { useState } from 'react'

import { useTheme } from '@mui/material/styles'
import { PaletteMode } from '@mui/material'


export const useCurrentThemeMode = () => {
    const [mode, setMode] = useState<PaletteMode>('light')
    const theme = useTheme()

    const changeMode = () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'))
    }

    return { mode, changeMode, theme }
};
