import React, { Fragment, useState } from 'react'
import classes from './components/root.module.css'
import { Outlet } from 'react-router-dom'

import Box from '@mui/material/Box'

import { menuItems } from './components/superAdminLayoutMenu'

import SideNavBar from '../nav/sideNavBar/SideNavBar'
import ControlPanelFooter from '../footer/controlPanel/ControlPanelFooter'

function SuperAdminLayout() {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  return (
    <Fragment>
      <SideNavBar {...{ isOpen, setIsOpen, menuItems }} />
      <Box className={isOpen ? classes.shrinkContent : classes.expandContent}>
        <Outlet />
        <ControlPanelFooter />
      </Box>
    </Fragment>
  )
}

export default SuperAdminLayout