import React, { Fragment, useEffect, useState, createContext } from 'react'
import { authenticationContext } from './components/interface'
import classes from './styles/authenticationPage.module.css'
import 'aos/dist/aos.css'
import Aos from 'aos'

import Grid from '@mui/material/Grid'

import Login from './authForms/Login'
import GreetingSide from './authForms/GreetingSide'
import Registration from './authForms/Registration'
import ForgetPassword from './authForms/ForgetPassword'

import { MountedBox } from "../custom/styledComponents/styledComponents"
import { LoginPageSVG, RegistrationPageSVG, ForgetPasswordPageSVG } from '../asset/dynamicSVGs'
import { loginGreetingNotes, registrationGreetingNotes, forgetPasswordGreetingNotes } from './authForms/utils'


export const formContext = createContext<authenticationContext>({} as authenticationContext)

export default function Authentication() {
    const [loginForm, setLoginForm] = useState(true)
    const [registrationForm, setRegistrationForm] = useState(false)
    const [forgetPasswordForm, setForgetPasswordForm] = useState(false)

    useEffect(() => {
        Aos.init({ duration: 1500 })
    }, [])

    return (
        <Fragment>
            <MountedBox>
                <formContext.Provider value={{
                    loginForm, setLoginForm, registrationForm, setRegistrationForm, forgetPasswordForm, setForgetPasswordForm,
                }}>
                    {
                        loginForm ?
                            <LoginView /> : registrationForm ? <RegistrationView /> : forgetPasswordForm ? <ForgetPasswordView />
                                : null
                    }
                </formContext.Provider>
            </MountedBox>
        </Fragment>
    )
};

const LoginView = () => {
    return (
        <Grid container>
            <Grid item xs={12} md={7} className={classes.greetingContainer} data-aos="fade-left">
                <GreetingSide
                    heading={loginGreetingNotes.heading}
                    subHeading={loginGreetingNotes.subHeading}
                    children={<LoginPageSVG height='450' width='450' />}
                />
            </Grid>
            <Grid item xs={12} md={5} className={classes.credentialsContainer} data-aos="fade-right">
                <Login />
            </Grid>
        </Grid>
    )
};

const RegistrationView = () => {
    return (
        <Grid container>
            <Grid item xs={12} md={6} className={classes.greetingContainer} data-aos="fade-right">
                <GreetingSide
                    heading={registrationGreetingNotes.heading}
                    subHeading={registrationGreetingNotes.subHeading}
                    children={<RegistrationPageSVG height='450' width='450' />}
                />
            </Grid>
            <Grid item xs={12} md={6} className={classes.credentialsContainer} data-aos="fade-left">
                <Registration />
            </Grid>
        </Grid>
    )
};

const ForgetPasswordView = () => {
    return (
        <Grid container>
            <Grid item xs={12} md={7} className={classes.greetingContainer} data-aos="fade-left">
                <GreetingSide
                    heading={forgetPasswordGreetingNotes.heading}
                    subHeading={forgetPasswordGreetingNotes.subHeading}
                    children={<ForgetPasswordPageSVG height='476' width='476' />}
                />
            </Grid>
            <Grid item xs={12} md={5} className={classes.credentialsContainer} data-aos="fade-right">
                <ForgetPassword />
            </Grid>
        </Grid>
    )
};