import React, { Fragment } from 'react'
import { FormikProps } from 'formik'

import dayjs, { Dayjs } from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import Loader from '../../components/Loader/Loader'

type calendarType = "month" | "year"

interface dateSelectorProps<FormValues> {
    label: string
    minDate?: string
    maxDate?: string
    loading?: boolean
    disabled?: boolean
    disablePast?: boolean
    name: keyof FormValues
    sx?: React.CSSProperties
    calendarView?: calendarType[]
    Formik: FormikProps<FormValues>
    openFrom?: "day" | "month" | "year"
}

export default function DateSelector<FormValues>(props: dateSelectorProps<FormValues>) {
    const { name, label, Formik, loading, disabled, openFrom, calendarView, minDate, maxDate, disablePast, sx } = props

    const handleDateChange = (date: Dayjs | null) => {
        Formik.setFieldValue(name as string, date)
    }

    return (
        <Fragment>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    sx={{ ...sx }}
                    name={name as string}
                    label={loading ? (<Loader padding="0 0" size={20} />) : label}
                    value={Formik.values[name] as unknown as Dayjs | null}
                    onChange={handleDateChange}
                    disabled={disabled}
                    format="DD MMM YYYY"
                    openTo={openFrom}
                    views={calendarView}
                    disablePast={disablePast}
                    minDate={dayjs(minDate, "DD/MM/YYYY")}
                    maxDate={dayjs(maxDate, "DD/MM/YYYY")}

                    slotProps={{
                        textField: () => ({
                            size: 'small',
                            focused: false,
                            fullWidth: true,
                            error: Boolean(Formik.touched[name] && Formik.errors[name]),
                            InputProps: {
                                sx: { svg: { color: 'primary.main' } }
                            }
                        }),
                    }}
                />
            </LocalizationProvider>
        </Fragment>
    )
};


//Link: https://mui.com/x/react-date-pickers/
//Link: https://www.youtube.com/watch?v=5t6gWvUMw0A&t=251s





