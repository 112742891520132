import React, { Fragment } from 'react';
import './App.css';

import { RouterProvider } from 'react-router-dom';
import Routes from './routes/Routes';

import ThemeProvider from './theme/ThemeProvider';
import StyleWrapper from './components/styleWrapper/StyleWrapper';

import ErrorIndicator from './custom/errors/ErrorIndicator';
import { SiteFavicon } from './theme/controller/Logo';

function App() {
  const { routes } = Routes()

  return (
    <Fragment>
      <ThemeProvider>
        <StyleWrapper>
          <RouterProvider router={routes} />
          <ErrorIndicator />
          <SiteFavicon />
        </StyleWrapper>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;