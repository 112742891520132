import { lazy } from 'react'

// Super Admin Components:::
export const Dashboard = lazy(() => import("../../../pages/superAdminPanel/dashboard/Dashboard"))

// Examiner :::
export const Examiners = lazy(() => import("../../../pages/common/master/examiner/Examiner"))
export const CustomizeExaminer = lazy(() => import("../../../pages/common/master/examiner/customizeExaminer/CustomizeExaminer"))

// Exam :::
export const Exams = lazy(() => import("../../../pages/common/master/exams/Exams"))
export const CustomizeExam = lazy(() => import("../../../pages/common/master/exams/customizeExams/CustomizeExams"))

// Syllabus :::
export const Syllabus = lazy(() => import("../../../pages/common/master/syllabus/Syllabus"))
export const CustomizeSyllabus = lazy(() => import("../../../pages/common/master/syllabus/customizeSyllabus/CustomizeSyllabus"))

// Questions :::
export const Questions = lazy(() => import("../../../pages/common/question/Questions"))
export const CustomizeQuestion = lazy(() => import("../../../pages/common/question/customizeQuestions/singleSet/CustomizeQuestion"))
export const EasyUpload = lazy(() => import("../../../pages/common/question/customizeQuestions/bulkUpload/EasyUpload"))
export const BulkUpload = lazy(() => import("../../../pages/common/question/customizeQuestions/bulkUpload/BulkUpload"))

// User Account :::
export const UserAccount = lazy(() => import("../../../pages/common/account/UserAccount"))

// Current All Users ::: 
export const AllUsers = lazy(() => import("../../../pages/superAdminPanel/users/Users"))
export const CustomizeUser = lazy(() => import("../../../pages/superAdminPanel/users/customizeUser/CustomizeUser"))

// Terms & Conditions :::
export const TermsAndConditions = lazy(() => import("../../../pages/common/legislation/TermsAndConditions"))

// Blogs :::
export const CurrentAffairs = lazy(() => import("../../../pages/common/blogs/currentAffair/CurrentAffair"))
export const CustomizeCurrentAffair = lazy(() => import("../../../pages/common/blogs/currentAffair/customizeCurrentAffair/CustomizeCurrentAffair"))
export const Vacancies = lazy(() => import("../../../pages/common/blogs/vacancies/Vacancies"))
export const CustomizeVacancy = lazy(() => import("../../../pages/common/blogs/vacancies/customizeVacancy/CustomizeVacancy"))

// Quiz :::
export const Quiz = lazy(() => import("../../../pages/common/quiz/Quiz"))

// Product Management :::
export const ProductManagement = lazy(() => import("../../../pages/common/productManagement/ProductManagement"))

// Test Series :::
export const TestSeries = lazy(() => import("../../../pages/common/tests/testSeries/TestSeries"))
export const CustomizeTestSeries = lazy(() => import("../../../pages/common/tests/testSeries/customizeTestSeries/CustomizeTestSeries"))
export const CreateTestPaper = lazy(() => import("../../../pages/common/tests/createTestPaper/CreateTestPaper"))
export const TestPapers = lazy(() => import("../../../pages/common/tests/testPapers/TestPapers"))
// 404 Component:::
export const PageNotFound = lazy(() => import("../../../layout/404/PageNotFound"))



