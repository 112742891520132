import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { allExaminer, examinerStructure } from './interface'

import {
    getAllExaminerAsync, createExaminerAsync,
    getExaminerByIDAsync, updateExaminerAsync,
    examinerCurrentStatusUpdateAsync, deleteExaminerAsync,
    examinerWebVisibilityAsync,
} from './examinerAsync'


interface examinerSliceStates {
    loading: boolean
    statusLoading: boolean
    deleteLoading: boolean
    webVisibilitLoading: boolean
    createExaminer: { code: number, message: string } | null
    getAllExaminer: allExaminer | null
    getExaminerByID: examinerStructure | null
    updateExaminer: { code: number, message: string } | null
    examinerCurrentStatus: { code: number, message: string } | null
    examinerWebVisibility: { code: number, message: string } | null
    deleteExaminer: { code: number, message: string } | null
}

const initialState: examinerSliceStates = {
    loading: false,
    statusLoading: false,
    deleteLoading: false,
    webVisibilitLoading: false,
    createExaminer: null,
    getAllExaminer: null,
    getExaminerByID: null,
    updateExaminer: null,
    deleteExaminer: null,
    examinerCurrentStatus: null,
    examinerWebVisibility: null,
}

const examinerSlice = createSlice({
    name: "examinerSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(getAllExaminerAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getAllExaminerAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getAllExaminer = action.payload
        })
        builder.addMatcher(isAnyOf(getAllExaminerAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(createExaminerAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(createExaminerAsync.fulfilled), (state, action) => {
            state.loading = false
            state.createExaminer = action.payload
        })
        builder.addMatcher(isAnyOf(createExaminerAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(getExaminerByIDAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getExaminerByIDAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getExaminerByID = action.payload?.data
        })
        builder.addMatcher(isAnyOf(getExaminerByIDAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(updateExaminerAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(updateExaminerAsync.fulfilled), (state, action) => {
            state.loading = false
            state.updateExaminer = action.payload
        })
        builder.addMatcher(isAnyOf(updateExaminerAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(examinerCurrentStatusUpdateAsync.pending), (state, action) => {
            state.statusLoading = true
        })
        builder.addMatcher(isAnyOf(examinerCurrentStatusUpdateAsync.fulfilled), (state, action) => {
            state.statusLoading = false
            state.examinerCurrentStatus = action.payload
        })
        builder.addMatcher(isAnyOf(examinerCurrentStatusUpdateAsync.rejected), (state, action) => {
            state.statusLoading = false
        })
        builder.addMatcher(isAnyOf(examinerWebVisibilityAsync.pending), (state, action) => {
            state.webVisibilitLoading = true
        })
        builder.addMatcher(isAnyOf(examinerWebVisibilityAsync.fulfilled), (state, action) => {
            state.webVisibilitLoading = false
            state.examinerWebVisibility = action.payload
        })
        builder.addMatcher(isAnyOf(examinerWebVisibilityAsync.rejected), (state, action) => {
            state.webVisibilitLoading = false
        })
        builder.addMatcher(isAnyOf(deleteExaminerAsync.pending), (state, action) => {
            state.deleteLoading = true
        })
        builder.addMatcher(isAnyOf(deleteExaminerAsync.fulfilled), (state, action) => {
            state.deleteLoading = false
            state.deleteExaminer = action.payload
        })
        builder.addMatcher(isAnyOf(deleteExaminerAsync.rejected), (state, action) => {
            state.deleteLoading = false
        })
    },
    reducers: {
        emptyExaminer: () => initialState,
        emptyDeleteExaminer: (state, action) => { state.deleteExaminer = null },
        exptyExaminerCurrentStatus: (state, action) => { state.examinerCurrentStatus = null },
        emptyExaminerWebVisibility: (state, action) => { state.examinerWebVisibility = null },
    }
})

export const { emptyExaminer, emptyDeleteExaminer, exptyExaminerCurrentStatus, emptyExaminerWebVisibility } = examinerSlice.actions;
export default examinerSlice.reducer;