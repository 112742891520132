import React, { Fragment, useContext, useState, useMemo } from 'react'
import classes from '../styles/credentials.module.css'

import { formContext } from '../Authentication'
import { Formik, Form } from 'formik'
import { useSnackAlert } from '../../components/snackAlert/SnackAlert'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import LoginIcon from '@mui/icons-material/Login'
import EmailIcon from "@mui/icons-material/Email"

import GoogleLogin from './GoogleLogin'
import FormSalutation from './FormSalutation'
import LoadingButton from '../../components/LoadingButton/LoadingButton'

import { accountTypes } from '../../redux/slice/auth/constant'
import { AuthInputField, AuthPasswordField, RadioInput } from '../../custom/inputComponent'
import { loginState, loginInitialValues, loginVadidationSchema } from "./utils"

// API Section :::
import { authLogin, saveCurrentUserDetails } from '../../redux/slice/auth/authAsync'
import { useAppDispatch, useAppSelector } from '../../redux/store/hooks'

function Login() {
    const dispatch = useAppDispatch()
    const cotext = useContext(formContext)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const { setLoginForm, setRegistrationForm, setForgetPasswordForm } = cotext

    const { setSeverity, setOpen, setMessage } = useSnackAlert()
    const { loading, responseData } = useAppSelector((state) => state.login)

    const onSubmit = async (values: loginState) => {
        const { email, password } = values
        dispatch(authLogin({ username: email, password: password }))
    };

    useMemo(() => {
        if (responseData.code === 200) {
            dispatch(saveCurrentUserDetails())
            setSeverity('success')
            setOpen(true)
            setMessage(responseData.message)
        }
    }, [responseData])

    return (
        <Fragment>
            <Stack className={classes.loginFormContainer}>

                <FormSalutation text='Welcome Back' />

                <Box className={classes.loginFormSection}>

                    <Formik initialValues={loginInitialValues} validationSchema={loginVadidationSchema} onSubmit={onSubmit}>
                        {
                            (Formik) => {
                                return (
                                    <Form className={classes.formContent}>

                                        <Box className={classes.accountType}>
                                            <RadioInput<loginState> name='accountType' Formik={Formik} rowWise
                                                radioGroups={accountTypes('web')}
                                            />
                                        </Box>

                                        <AuthInputField<loginState> name='email' type='email' label='Email'
                                            Formik={Formik} loading={false} icon={EmailIcon} sx={{ marginBottom: 2 }}
                                            disabled={!!(Formik.values.accountType === '')}
                                        />

                                        <AuthPasswordField<loginState> name='password' label='Password'
                                            {...{ Formik, showPassword, setShowPassword }} loading={false} sx={{ marginBottom: 0.5 }}
                                            disabled={!!(Formik.values.accountType === '')}
                                        />

                                        <Box className={classes.forgetPasswordSection}>

                                            <Typography className={classes.forgetPassword}
                                                onClick={() => { setLoginForm(false); setRegistrationForm(false); setForgetPasswordForm(true) }}
                                            >
                                                Forget Password
                                            </Typography>
                                        </Box>

                                        <Box className={classes.loadingButton}>
                                            <LoadingButton loading={loading} StartIcon={LoginIcon}
                                                variant='contained' buttonText='Login' loadingPosition='start'
                                            />

                                            <GoogleLogin {...{ Formik }} />
                                        </Box>

                                        <Box className={classes.goToForm}>
                                            <Typography >
                                                Don't Have An Account ?
                                                <span onClick={() => { setLoginForm(false); setRegistrationForm(true); setForgetPasswordForm(false) }}
                                                    className={classes.reDirect}> Register</span>
                                            </Typography>
                                        </Box>
                                    </Form>
                                )
                            }
                        }
                    </Formik>
                </Box>
            </Stack>
        </Fragment>
    )
}

export default Login;