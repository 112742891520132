import { createSlice } from "@reduxjs/toolkit";
import { getCurrentUserDetailsAsync } from './authAsync';
import { userDetailsStructure } from "./interface";


interface authLoginState {
    loading: boolean
    responseData: userDetailsStructure | null
}

const initialState: authLoginState = {
    loading: false,
    responseData: null
}

const getCurrentUserDetailsSlice = createSlice({
    name: "getCurrentUserDetailsSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addCase((getCurrentUserDetailsAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((getCurrentUserDetailsAsync.fulfilled), (state, action) => {
            state.loading = false
            state.responseData = action.payload?.data
        })
        builder.addCase((getCurrentUserDetailsAsync.rejected), (state, action) => {
            state.loading = false
        })
    },
    reducers: { emptyCurrentUserDetailsState: () => initialState },
})

export const { emptyCurrentUserDetailsState } = getCurrentUserDetailsSlice.actions;
export default getCurrentUserDetailsSlice.reducer;