import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { syllabusStructure, subHeadingInterface, subjectStructure, subjectParams } from './interface';
import {
    createSyllabusAsync, getAllSyllabusAsync, getSyllabusByIDAsync, updateSyllabusAsync,
    getAllSyllabusBySubHeadingsAsync, syllabusCurrentStatusUpdateAsync, deleteSyllabusAsync,
    getSyllabusByExamIDAsync, getHeadingsBySubjectIDAsync,
} from './syllabusAsync'


interface syllabusSliceStates {
    loading: boolean
    statusLoading: boolean
    subjectParamsLoading: boolean
    createSyllabus: { code: number, message: string } | null
    getAllSyllabus: syllabusStructure | null
    getAllSyllabusBySubHeadings: subHeadingInterface | null
    getSyllabusByID: subjectStructure | null
    updateSyllabus: { code: number, message: string } | null
    syllabusCurrentStatus: { code: number, message: string } | null
    syllabusByExamID: syllabusStructure | null
    headingSubheadings : subjectParams | null
}

const initialState: syllabusSliceStates = {
    loading: false,
    statusLoading: false,
    subjectParamsLoading: false,
    createSyllabus: null,
    getAllSyllabus: null,
    getAllSyllabusBySubHeadings: null,
    getSyllabusByID: null,
    updateSyllabus: null,
    syllabusCurrentStatus: null,
    syllabusByExamID: null,
    headingSubheadings: null
}

const syllabusSlice = createSlice({
    initialState,
    name: "syllabusSlice",
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(getAllSyllabusAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getAllSyllabusAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getAllSyllabus = action.payload
        })
        builder.addMatcher(isAnyOf(getAllSyllabusAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(getAllSyllabusBySubHeadingsAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getAllSyllabusBySubHeadingsAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getAllSyllabusBySubHeadings = action.payload
        })
        builder.addMatcher(isAnyOf(getAllSyllabusBySubHeadingsAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(createSyllabusAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(createSyllabusAsync.fulfilled), (state, action) => {
            state.loading = false
            state.createSyllabus = action.payload
        })
        builder.addMatcher(isAnyOf(createSyllabusAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(getSyllabusByIDAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getSyllabusByIDAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getSyllabusByID = action.payload?.data
        })
        builder.addMatcher(isAnyOf(getSyllabusByIDAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(updateSyllabusAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(updateSyllabusAsync.fulfilled), (state, action) => {
            state.loading = false
            state.updateSyllabus = action.payload
        })
        builder.addMatcher(isAnyOf(updateSyllabusAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(syllabusCurrentStatusUpdateAsync.pending), (state, action) => {
            state.statusLoading = true
        })
        builder.addMatcher(isAnyOf(syllabusCurrentStatusUpdateAsync.fulfilled), (state, action) => {
            state.statusLoading = false
            state.syllabusCurrentStatus = action.payload
        })
        builder.addMatcher(isAnyOf(syllabusCurrentStatusUpdateAsync.rejected), (state, action) => {
            state.statusLoading = false
        })
        builder.addMatcher(isAnyOf(getSyllabusByExamIDAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getSyllabusByExamIDAsync.fulfilled), (state, action) => {
            state.loading = false
            state.syllabusByExamID = action.payload
        })
        builder.addMatcher(isAnyOf(getSyllabusByExamIDAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(getHeadingsBySubjectIDAsync.pending), (state, action) => {
            state.subjectParamsLoading = true
        })
        builder.addMatcher(isAnyOf(getHeadingsBySubjectIDAsync.fulfilled), (state, action) => {
            state.subjectParamsLoading = false
            state.headingSubheadings = action.payload
        })
        builder.addMatcher(isAnyOf(getHeadingsBySubjectIDAsync.rejected), (state, action) => {
            state.subjectParamsLoading = false
        })
    },
    reducers: {
        emptySyllabus: () => initialState,
        emptySyllabusCurrentStatus: (state, action) => { state.syllabusCurrentStatus = null }
    }
})

export const { emptySyllabus, emptySyllabusCurrentStatus } = syllabusSlice.actions;
export default syllabusSlice.reducer;