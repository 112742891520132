import React, { Fragment, useEffect, useMemo } from 'react'

import { Hidden } from '@mui/material'
import { Outlet } from 'react-router-dom'
import TopNavBar from '../../navigation/topNavBar/TopNavBar'
import WebPanelFooter from '../footer/webPanel/WebPanelFooter'
import BottomNavBar from '../../navigation/bottomNavBar/BottomNavBar'

// API Section :::
import { getAllExamAsync } from '../../redux/slice/common/exam/examAsync'
import { getAllQuizAsync } from '../../redux/slice/common/quiz/quizAsync'
import { getAllExaminerAsync } from '../../redux/slice/common/examiner/examinerAsync'
import { getAllVacanciesAsync } from '../../redux/slice/common/vacancies/vacanciesAsync'
import { getAllCurrentAffairsAsync } from '../../redux/slice/common/currentAffairs/currentAffairsAsync'
import { authGuestUserTokenGeneration } from '../../redux/slice/auth/authAsync'
import { useAppDispatch, useAppSelector } from '../../redux/store/hooks'
function WebLayout() {

  const dispatch = useAppDispatch()
  const { responseData } = useAppSelector((state) => state.guestUser)

  useEffect(() => {
    dispatch(authGuestUserTokenGeneration())
  }, [])

  useMemo(() => {
    if (responseData?.code === 200) {
      dispatch(getAllQuizAsync())
      dispatch(getAllExamAsync({}))
      dispatch(getAllExaminerAsync({}))
      dispatch(getAllVacanciesAsync({ page: 1, limit: 10, }))
      dispatch(getAllCurrentAffairsAsync({ page: 1, limit: 10, }))
    }
  }, [responseData])


  return (
    <Fragment>
      <TopNavBar />
      <Outlet />
      <Hidden smUp>
        <BottomNavBar />
      </Hidden>
      <WebPanelFooter />
    </Fragment>
  )
}

export default WebLayout;