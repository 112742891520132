export const genders = [
    { id: 1, label: "Male", value: 'male' },
    { id: 2, label: "Female", value: 'female' },
];

export function accountTypes(panel: 'superAdmin' | 'admin' | 'web') {
    const guest = { id: 1, label: "Guest", value: "guest" };
    const student = { id: 2, label: "Student", value: "student" };
    const institute = { id: 3, label: "Institute", value: "institute" };

    switch (panel) {
        case "web": return [{ ...student }, { ...institute }];
        case "admin": return [{ ...student }, { ...institute }];
        case "superAdmin": return [{ ...guest }, { ...student }, { ...institute }];
        default: return [];
    }
};