import React, { Fragment, useContext, useState, useMemo } from 'react'
import classes from '../styles/credentials.module.css'

import { useNavigate } from 'react-router-dom'
import { formContext } from '../Authentication'
import { Formik, Form } from 'formik'
import { useSnackAlert } from '../../components/snackAlert/SnackAlert'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Checkbox from "@mui/material/Checkbox"
import Typography from '@mui/material/Typography'
import EmailIcon from "@mui/icons-material/Email"
import PersonIcon from "@mui/icons-material/Person"
import HowToRegIcon from "@mui/icons-material/HowToReg"
import LockPersonIcon from "@mui/icons-material/LockPerson"
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone"
import FormControlLabel from "@mui/material/FormControlLabel"

import LoadingButton from '../../components/LoadingButton/LoadingButton'

import { ProfilePicture } from '../../custom/uploads'
import { accountTypes, genders } from '../../redux/slice/auth/constant'
import { AuthInputField, AuthPasswordField, RadioInput, DateSelector } from '../../custom/inputComponent'
import { registrationState, registrationInitialValues, registrationVadidationSchema } from "./utils";

// API Section :::
import { authRegistration } from '../../redux/slice/auth/authAsync'
import { emptyAuthRegistrationState } from '../../redux/slice/auth/authRegistrationSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store/hooks'

function Registration() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const cotext = useContext(formContext)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const { setLoginForm, setRegistrationForm, setForgetPasswordForm } = cotext

    const { setSeverity, setOpen, setMessage } = useSnackAlert()
    const { loading, responseData } = useAppSelector((state) => state.registration)

    const onSubmit = async (values: registrationState) => {
        const {
            image, accountType, name, DOB, gender, email,
            password, confirm_password, agreeTC, phone, status
        } = values

        const formData = new FormData()
        formData.append('logo', image[0])
        formData.append('account_type', accountType)
        formData.append('user_type', accountType === 'institute' ? 'admin' : 'user')

        formData.append('name', name)
        formData.append('mobile', phone)
        formData.append('gender', gender)
        if (DOB) { formData.append('dob', DOB.toString()) }

        formData.append('email', email)
        formData.append('status', status)
        formData.append('password', password.toString())

        dispatch(authRegistration(formData))
    };

    useMemo(() => {
        if (responseData.code === 201) {
            setSeverity('success')
            setOpen(true)
            setMessage(responseData.message)
            setLoginForm(true)
            setRegistrationForm(false)
            setForgetPasswordForm(false)
            dispatch(emptyAuthRegistrationState())
        }
    }, [responseData])

    return (
        <Fragment>
            <Stack className={classes.registrationFormSection}>

                <Formik initialValues={registrationInitialValues} validationSchema={registrationVadidationSchema} onSubmit={onSubmit}>

                    {(Formik) => {
                        return (
                            <Form className={classes.formContent}>

                                <Box className={classes.profileSchema}>

                                    <ProfilePicture<registrationState> Formik={Formik} name='image'
                                        error={Boolean(Formik.touched.image && Formik.errors.image)}
                                    />

                                    <Box className={classes.accountGender}>
                                        <RadioInput name='accountType' Formik={Formik} radioGroups={accountTypes('web')} rowWise />

                                        <RadioInput name='gender' Formik={Formik} radioGroups={genders} rowWise
                                            disabled={Formik.values.accountType === "institute"}
                                        />
                                    </Box>
                                </Box>

                                <Box className={classes.namePhone}>
                                    <AuthInputField<registrationState> name='name' type='text' label='Full Name'
                                        Formik={Formik} loading={false} icon={PersonIcon} sx={{ marginBottom: 2 }}
                                    />

                                    <AuthInputField<registrationState> name='phone' type='tel' label='Phone'
                                        Formik={Formik} loading={false} icon={PhoneIphoneIcon} sx={{ marginBottom: 2 }}
                                    />
                                </Box>

                                <Box className={classes.dobEmail}>
                                    <DateSelector<registrationState> name="DOB" label="Birth Date" Formik={Formik} sx={{ marginBottom: 2 }} />

                                    <AuthInputField<registrationState> name='email' type='email' label='Email'
                                        Formik={Formik} loading={false} icon={EmailIcon} sx={{ marginBottom: 2 }}
                                    />
                                </Box>

                                <Box className={classes.passwordSection}>

                                    <AuthPasswordField<registrationState> name='password' label='Password'
                                        {...{ Formik, showPassword, setShowPassword }} loading={false} sx={{ marginBottom: 2 }}
                                    />

                                    <AuthInputField<registrationState> name='confirm_password' type='password' label='Confirm Password'
                                        Formik={Formik} loading={false} icon={LockPersonIcon} sx={{ marginBottom: 2 }}
                                    />
                                </Box>

                                <Box className={classes.actBox}>
                                    <FormControlLabel sx={{ mt: -2 }} name="agreeTC"
                                        checked={Formik.values.agreeTC} onChange={Formik.handleChange}

                                        control={<Checkbox size="small" sx={{ position: 'relative', bottom: 1 }} />}
                                        label={
                                            <Typography className={(Formik.touched.agreeTC && Formik.errors.agreeTC) ? classes.atc_error : ''}>
                                                I Agree To The
                                            </Typography>
                                        }
                                    />

                                    <Typography className={(Formik.touched.agreeTC && Formik.errors.agreeTC) ? classes.actText_error : classes.actText}
                                        onClick={() => navigate("/terms-conditions")}
                                    >
                                        Terms And Conditions
                                    </Typography>
                                </Box>

                                <Box className={classes.loadingButton}>
                                    <LoadingButton loading={loading} StartIcon={HowToRegIcon}
                                        variant='contained' buttonText='Register' loadingPosition='start'
                                    />
                                </Box>

                                <Box className={classes.goToForm}>
                                    <Typography >
                                        Already Have An Account ?
                                        <span onClick={() => { setLoginForm(true); setRegistrationForm(false); setForgetPasswordForm(false) }}
                                            className={classes.reDirect}> Login</span>
                                    </Typography>
                                </Box>
                            </Form>
                        )
                    }}
                </Formik>
            </Stack>
        </Fragment>
    )
}

export default Registration