import React, { Fragment, useEffect } from 'react'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import CachedIcon from '@mui/icons-material/Cached'
import InputAdornment from '@mui/material/InputAdornment'

import Loader from '../../components/Loader/Loader'


interface searchTextQueryProps {
    loading: boolean
    placeholder: string
    textQuery: string | null
    apiDispatchFunction: () => void
    setTextQuery: (text: string) => void
}

function SearchTextQuery(props: searchTextQueryProps) {
    const { textQuery, setTextQuery, apiDispatchFunction, placeholder, loading } = props

    useEffect(() => {
        if (textQuery !== null) {
            const debounceTime = setTimeout(() => {
                apiDispatchFunction()
            }, 500);
            return () => clearTimeout(debounceTime)
        }
    }, [textQuery])


    const handleReset = () => {
        setTextQuery("")
        apiDispatchFunction()
    }

    return (
        <Fragment>
            <FormControl>
                <TextField
                    fullWidth
                    value={textQuery}
                    onChange={(event) => setTextQuery(event.target.value)}
                    placeholder={placeholder}
                    InputProps={{
                        sx: { borderRadius: "10px", height: "37px", fontSize: "14.5px", fontWeight: 500 },
                        endAdornment: (
                            <InputAdornment position='end' sx={{ marginRight: -1.5, }}>
                                {
                                    loading ? <Box component="span" sx={{ mr: 1, mt: 0.5 }}><Loader padding="0 0" size={20} /></Box> :
                                        <IconButton onClick={handleReset}>
                                            <CachedIcon fontSize='small' sx={{ ":hover": { color: (theme) => theme.palette.primary.main } }} />
                                        </IconButton>
                                }


                            </InputAdornment>
                        )
                    }}
                />
            </FormControl>
        </Fragment>
    )
}

export default SearchTextQuery;