import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosClient } from "../../../axiosClient/AxiosClient";


// Create Examiner:::
export const createExaminerAsync = createAsyncThunk(
    "createExaminer/examiner", async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/examiner", payload, toolkit)
    }
)

// Get All Examiner:::
interface examinerParams { search?: string | null, status?: "active" | "inactive" }
export const getAllExaminerAsync = createAsyncThunk(
    "getAllExaminer/examiner", async (params: examinerParams, toolkit) => {
        return await AxiosClient("GET", "/examiner", [], toolkit, params)
    }
);

// Get By ID:::
export const getExaminerByIDAsync = createAsyncThunk(
    "getExaminerByID/examiner", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("GET", `/examiner/${payload.id}`, [], toolkit)
    }
)

// Update Examiner:::
export const updateExaminerAsync = createAsyncThunk(
    "updateExaminer/examiner", async (payload: { id: number, formData: FormData }, toolkit) => {
        return await AxiosClient("POST", `/examiner/${payload.id}`, payload.formData, toolkit)
    }
)

// Active/Inactive Status:::
export const examinerCurrentStatusUpdateAsync = createAsyncThunk(
    "examinerCurrentStatus/examiner/status", async (payload: { id: number, status: "active" | "inactive" }, toolkit) => {
        return await AxiosClient("POST", "/examiner/update/status", payload, toolkit)
    }
)

// Examiner Web Visibility :::
export const examinerWebVisibilityAsync = createAsyncThunk(
    "examinerWebVisibility/show-hide-examiner", async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/show-hide-examiner", payload, toolkit)
    }
)

// Delete Examiner:::
export const deleteExaminerAsync = createAsyncThunk(
    "deleteExaminer/examiner", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("DELETE", `/examiner/${payload.id}`, [], toolkit)
    }
)

