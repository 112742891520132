import React, { Fragment } from 'react'

import { Stack } from '@mui/system'
import { SvgIconProps } from '@mui/material/SvgIcon'
import { LoadingButton as AsyncButton } from '@mui/lab'

interface loadingButton {
    loading: boolean
    buttonText: string
    disabled?: boolean
    loadingText?: string
    handleClick?: () => void
    sx?: React.CSSProperties
    EndIcon?: React.ElementType<SvgIconProps>
    StartIcon?: React.ElementType<SvgIconProps>
    type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
    loadingPosition: 'start' | 'end'
    variant: 'contained' | 'outlined' | 'text'
    color?: 'error' | 'info' | 'inherit' | 'primary' | 'secondary' | 'success' | 'warning'
}

function LoadingButton(props: loadingButton) {
    const { variant, loading, disabled, buttonText, loadingText, StartIcon, EndIcon, loadingPosition, handleClick, sx, color, type } = props
    return (
        <Fragment>
            <Stack spacing={2} direction='row'>
                <AsyncButton
                    fullWidth
                    color={color}
                    loading={loading}
                    variant={variant}
                    disabled={disabled}
                    onClick={handleClick}
                    type={type || 'submit'}
                    endIcon={EndIcon && <EndIcon />}
                    startIcon={StartIcon && <StartIcon />}
                    loadingPosition={loadingPosition}
                    loadingIndicator={loadingText && loadingText}
                    sx={{ width: '200px', height: '35px', ...sx }}
                >
                    {buttonText}
                </AsyncButton>
            </Stack>
        </Fragment>
    )
}

export default LoadingButton;