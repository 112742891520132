import React, { Fragment, useState } from "react"
import { useNavigate } from "react-router-dom"

import Menu from "@mui/material/Menu"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Hidden from "@mui/material/Hidden"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import MenuItem from "@mui/material/MenuItem"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import { examMenus } from './components/manuList'
import { ModeContraller } from "../../theme/controller/ModeController"


export default function TopNavBar() {
    const navigate = useNavigate();
    const [anchorEL, setAnchorEL] = useState<null | HTMLElement>(null)

    const open = Boolean(anchorEL);
    const handleWorkout = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEL(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEL(null)
    }

    return (
        <Fragment>
            <AppBar elevation={0}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="logo"
                    >
                        {/* <img src={siteLogo} alt="siteLogo" style={{ width: '50px', height: 'auto' }} /> */}
                    </IconButton>
                    <Typography variant="h6" component="div"
                        sx={{ flexGrow: 1, fontWeight: 700 }}
                    >
                        Priksha Mantra
                    </Typography>

                    <Hidden smDown>
                        <Stack direction="row" spacing={2}>

                            <Button color="inherit" onClick={() => navigate('/')}
                                sx={{ fontWeight: "inherit", fontSize: "14px" }}
                            >
                                Home
                            </Button>

                            <Button
                                color="inherit"
                                id="workout_button"
                                onClick={handleWorkout}
                                aria-controls={open ? "workout_planning" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                endIcon={<KeyboardArrowDownIcon />}
                                sx={{ fontWeight: "inherit", fontSize: "14px" }}
                            >
                                Outlets
                            </Button>

                            <Button color="inherit" onClick={() => navigate('/auth')}
                                sx={{ fontWeight: "inherit", fontSize: "14px" }}
                            >
                                Login
                            </Button>
                        </Stack>
                    </Hidden>

                    {/* <----- Exam Menu -----> */}
                    <Menu
                        id="workout_planning"
                        anchorEl={anchorEL}
                        open={open}
                        MenuListProps={{ "aria-labelledby": "workout_planning" }}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                        {
                            examMenus?.length && examMenus.map((ele) => {
                                return (
                                    <MenuItem key={ele.id}
                                        onClick={() => {
                                            handleClose()
                                            navigate(ele.path)
                                        }}
                                    >
                                        <Typography>{ele.name}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                    </Menu>
                    <ModeContraller />
                </Toolbar>
            </AppBar>
        </Fragment >
    );
};


