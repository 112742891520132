import { teal, grey } from '@mui/material/colors'
import { Components } from '@mui/material/styles'

const getMuiAppBar = (mode: 'light' | 'dark'): Components['MuiAppBar'] => ({
    styleOverrides: {
        root: {
            backgroundColor: mode === "dark" ? teal[700] : teal[300],
            color: mode === "dark" ? grey[300] : grey[800],
            height: "50px",
            position: "static",
            display: "flex",
            justifyContent: "center",
            fontWeight: "600",
        },
    },
});

export default getMuiAppBar;
