import { createSlice } from "@reduxjs/toolkit";
import { saveCurrentUserDetails } from './authAsync';

interface userDetails {
    name: string, gender: string, email: string,
    mobile: string, type: "superadmin" | "admin" | "user" | null
}

interface authLoginState {
    loading: boolean
    responseData: { code: number | null, message: string, data: userDetails }
}

const initialState: authLoginState = {
    loading: false,
    responseData: {
        code: null, message: "",
        data: { email: "", gender: "", mobile: "", name: "", type: null }
    }
}

const saveCurrentUserDetailsSlice = createSlice({
    name: "saveCurrentUserDetailsSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addCase((saveCurrentUserDetails.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((saveCurrentUserDetails.fulfilled), (state, action) => {
            state.loading = false
            state.responseData = action.payload
            const userDetails = {
                name: action.payload?.data?.name,
                email: action.payload?.data?.email,
                gender: action.payload?.data?.gender,
                userType: action.payload?.data?.user_type
            }
            localStorage.setItem("userDetails", JSON.stringify(userDetails))
            window.location.reload()
            window.location.href = `/${action.payload?.data?.user_type}`
        })
        builder.addCase((saveCurrentUserDetails.rejected), (state, action) => {
            state.loading = false
        })
    },
    reducers: { emptySavedUserDetailsState: () => initialState },
})

export const { emptySavedUserDetailsState } = saveCurrentUserDetailsSlice.actions;
export default saveCurrentUserDetailsSlice.reducer;