import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosClient } from "../../../axiosClient/AxiosClient";

// Create Quiz:::
export const createQuizAsync = createAsyncThunk(
    "createQuiz/quiz/create-update", async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/quiz/create-update", payload, toolkit)
    }
)

// Get All Quiz:::
export const getAllQuizAsync = createAsyncThunk(
    "getAllQuiz/show-quiz", async (payload, toolkit) => {
        return await AxiosClient("GET", "/show-quiz", [], toolkit)
    }
);
