import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosClient } from "../../../axiosClient/AxiosClient";

export interface questionParams {
    status?: "active" | "inactive", "subject_id[]"?: number[], "heading_code[]"?: string[], "sub_heading_code[]"?: string[],
    search?: string | null, page?: number, limit?: number,
}


export const createQuestionAsync = createAsyncThunk(
    "createQuestion/question", async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/question", payload, toolkit)
    }
)

export const getAllQuestionsAsync = createAsyncThunk(
    "getAllQuestions/question", async (params: questionParams, toolkit) => {
        return await AxiosClient("GET", "/question", [], toolkit, params)
    }
);

export const getQuestionByIDAsync = createAsyncThunk(
    "getQuestionByID/question", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("GET", `/question/${payload.id}`, [], toolkit)
    }
)

export const updateQuestionAsync = createAsyncThunk(
    "updateQuestion/question", async (payload: { id: number, formData: FormData }, toolkit) => {
        return await AxiosClient("POST", `/question/${payload.id}`, payload.formData, toolkit)
    }
)

export const questionCurrentStatusUpdateAsync = createAsyncThunk(
    "questionCurrentStatus/question/status", async (payload: { id: number, status: "active" | "inactive" }, toolkit) => {
        return await AxiosClient("POST", "/question/update/status", payload, toolkit)
    }
)

export const deleteQuestionAsync = createAsyncThunk(
    "deleteQuestion/question", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("DELETE", `/question/${payload.id}`, [], toolkit)
    }
)