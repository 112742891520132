import { createSlice } from "@reduxjs/toolkit";
import { completeUserList, userDetailsStructure } from './interface';

import {
    getAllusersAsync, getUserDetailsByIDAsync,
    updateUserDetailsByIDAsync, userPasswordChangeAsync, userCurrentStatusUpdateAsync, deleteUserAsync,
} from './authAsync';

interface authUserOperationsState {
    loading: boolean
    statusLoading: boolean
    deleteLoading: boolean
    changePasswordLoading: boolean
    allUsers: completeUserList | null
    getuserByID: userDetailsStructure | null
    updateUser: { code: number, message: string } | null
    userCurrentStatus: { code: number, message: string } | null
    deleteUser: { code: number, message: string } | null
    changeUserPassword: { code: number, message: string } | null
}

const initialState: authUserOperationsState = {
    loading: false,
    statusLoading: false,
    deleteLoading: false,
    changePasswordLoading: false,
    allUsers: null,
    getuserByID: null,
    updateUser: null,
    userCurrentStatus: null,
    deleteUser: null,
    changeUserPassword: null,
}

const userOperationsSlice = createSlice({
    name: "userOperationsSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addCase((getAllusersAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((getAllusersAsync.fulfilled), (state, action) => {
            state.loading = false
            state.allUsers = action.payload
        })
        builder.addCase((getAllusersAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addCase((getUserDetailsByIDAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((getUserDetailsByIDAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getuserByID = action.payload?.data
        })
        builder.addCase((getUserDetailsByIDAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addCase((updateUserDetailsByIDAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((updateUserDetailsByIDAsync.fulfilled), (state, action) => {
            state.loading = false
            state.updateUser = action.payload
        })
        builder.addCase((updateUserDetailsByIDAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addCase((userCurrentStatusUpdateAsync.pending), (state, action) => {
            state.statusLoading = true
        })
        builder.addCase((userCurrentStatusUpdateAsync.fulfilled), (state, action) => {
            state.statusLoading = false
            state.userCurrentStatus = action.payload
        })
        builder.addCase((userCurrentStatusUpdateAsync.rejected), (state, action) => {
            state.statusLoading = false
        })
        builder.addCase((deleteUserAsync.pending), (state, action) => {
            state.deleteLoading = true
        })
        builder.addCase((deleteUserAsync.fulfilled), (state, action) => {
            state.deleteLoading = false
            state.deleteUser = action.payload
        })
        builder.addCase((deleteUserAsync.rejected), (state, action) => {
            state.deleteLoading = false
        })
        // 
        builder.addCase((userPasswordChangeAsync.pending), (state, action) => {
            state.changePasswordLoading = true
        })
        builder.addCase((userPasswordChangeAsync.fulfilled), (state, action) => {
            state.changePasswordLoading = false
            state.changeUserPassword = action.payload
        })
        builder.addCase((userPasswordChangeAsync.rejected), (state, action) => {
            state.changePasswordLoading = false
        })
    },
    reducers: {
        emptyUserState: () => initialState,
        emptyDeleteUserState: (state, action) => { state.deleteUser = null },
        emptyUserCurrentStatus: (state, action) => { state.userCurrentStatus = null },
        emptyUserChangePassword: (state, action) => { state.changeUserPassword = null }
    },
})

export const { emptyUserState, emptyDeleteUserState, emptyUserCurrentStatus, emptyUserChangePassword } = userOperationsSlice.actions;
export default userOperationsSlice.reducer;