import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface QuestionParameters { questionID: number, subjectID: number, headingCode: string, subheadingCode: string }

interface TestQuestionCount {
    bySubjectID: Record<number, number>
    byHeadingCode: Record<string, number>
    bySubheadingCode: Record<string, number>
}

interface TestUtilityStates {
    testQuestions: QuestionParameters[] | null,
    questionCount: TestQuestionCount
}


const initialState: TestUtilityStates = {
    testQuestions: null,
    questionCount: { bySubjectID: {}, byHeadingCode: {}, bySubheadingCode: {} },
}

const testUtilitySlice = createSlice({
    initialState,
    name: "testUtilitySlice",
    reducers: {
        testQuestionsList: (state, action: PayloadAction<QuestionParameters>) => {

            const { questionID, subjectID, headingCode, subheadingCode } = action.payload
            
            if (state.testQuestions) {
                const exist = state.testQuestions?.some((q) => q.questionID === action.payload.questionID)
                if (exist) {
                    state.testQuestions = state.testQuestions.filter((q) => q.questionID !== action.payload.questionID)

                    // Decrement :::
                    if (state.questionCount?.bySubjectID[subjectID]) {
                        state.questionCount.bySubjectID[subjectID]--;
                        if (state.questionCount.bySubjectID[subjectID] === 0) delete state.questionCount.bySubjectID[subjectID];
                    }
                    if (state.questionCount?.byHeadingCode[headingCode]) {
                        state.questionCount.byHeadingCode[headingCode]--;
                        if (state.questionCount.byHeadingCode[headingCode] === 0) delete state.questionCount.byHeadingCode[headingCode];
                    }
                    if (state.questionCount?.bySubheadingCode[subheadingCode]) {
                        state.questionCount.bySubheadingCode[subheadingCode]--;
                        if (state.questionCount.bySubheadingCode[subheadingCode] === 0) delete state.questionCount.bySubheadingCode[subheadingCode];
                    }

                } else {
                    state.testQuestions.push(action.payload)

                    // Increment :::
                    state.questionCount.bySubjectID[subjectID] = (state.questionCount?.bySubjectID[subjectID] || 0) + 1
                    state.questionCount.byHeadingCode[headingCode] = (state.questionCount.byHeadingCode[headingCode] || 0) + 1
                    state.questionCount.bySubheadingCode[subheadingCode] = (state.questionCount.bySubheadingCode[subheadingCode] || 0) + 1
                }
            } else { 
                state.testQuestions = [action.payload]
                state.questionCount.bySubjectID[subjectID] = 1
                state.questionCount.byHeadingCode[headingCode] = 1
                state.questionCount.bySubheadingCode[subheadingCode] = 1
            }
        },
        emptyTestUtility: () => initialState
    }
})

export const { testQuestionsList, emptyTestUtility } = testUtilitySlice.actions;
export default testUtilitySlice.reducer;






